// Import and register all your controllers from the importmap via controllers/**/*_controller
import {application} from "./application"

import Sortable from "@stimulus-components/sortable"
application.register("sortable", Sortable)

import AutoSumbit from "@stimulus-components/auto-submit"
application.register("auto-submit", AutoSumbit)

import Code from "./code_controller"
application.register("code", Code)

import Dropdown from "./dropdown_controller"
application.register("dropdown", Dropdown)

import Manytag from "./manytag_controller"
application.register("manytag", Manytag)

import Flash from "./flash_controller"
application.register("flash", Flash)

import Panel from "./panel_controller"
application.register("panel", Panel)

import Table from "./table_controller"
application.register("table", Table)

//import {eagerLoadControllersFrom} from "@hotwired/stimulus-loading"
//eagerLoadControllersFrom("controllers", application)

console.log("controllers loaded")
