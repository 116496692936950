import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["backdrop", "panel"]

  connect() {
    this.open()
  }

  open() {
    this.panelTarget.classList.remove("translate-x-full")
    this.backdropTarget.classList.remove("bg-gray-800/0")
    this.backdropTarget.classList.add("bg-gray-800/50")
    this.backdropTarget.style['z-index'] = '60'
  }

  close() {
    this.panelTarget.classList.add("translate-x-full")
    this.backdropTarget.classList.remove("bg-gray-800/50")
    this.backdropTarget.classList.add("bg-gray-800/0")
    this.backdropTarget.style['z-index'] = '-1'
  }
}
