import { Controller } from "@hotwired/stimulus"
import { patch, destroy } from "@rails/request.js"

import { EditorView, ViewPlugin, hoverTooltip } from "@codemirror/view"
import { linter } from "@codemirror/lint"
import { basicSetup } from "codemirror"
import { javascript } from "@codemirror/lang-javascript"
import { autocompletion } from "@codemirror/autocomplete"
import { tsSync, tsFacet, tsAutocomplete, tsHover, tsLinter, } from "@valtown/codemirror-ts"
import * as tsvfs from "@typescript/vfs"
import ts from "typescript"

// import { HighlightStyle, syntaxHighlighting } from '@codemirror/language'
// import { tags as t } from '@lezer/highlight'

export default class extends Controller {
  static targets = ["name", "editor"]
  static values = {url: String}

  async save() {
    const rep = await patch(this.urlValue, {
      body: JSON.stringify({
        name: this.nameTarget.value,
        code: this.userCode,
      }),
    })
    console.log(rep)
    // if (rep.ok) window.location.reload()
    // TODO
  }

  async destroy() {
    const rep = await destroy(this.urlValue)
    console.log(rep)
    if (rep.ok) window.location.reload()
    // TODO
  }

  connect() {
    this.userCode = this.editorTarget.getAttribute("user-code")
    this.systemCode = this.editorTarget.getAttribute("system-code")
    let editor

    tsvfs.createDefaultMapFromCDN({target: ts.ScriptTarget.ES2023}, ts.version, true, ts).then((fsMap) => {
      fsMap.set("index.ts", this.userCode);
      fsMap.set("project.ts", this.systemCode);

      const sys = tsvfs.createSystem(fsMap)
      const compilerOptions = {target: ts.ScriptTarget.ES2023, baseUrl: ".", paths: {project: ["./project"]}}
      const env = tsvfs.createVirtualTypeScriptEnvironment(sys, ["index.ts", "project.ts"], ts, compilerOptions)

      editor = new EditorView({
        doc: this.userCode,
        parent: this.editorTarget,

        extensions: [
          basicSetup,
          //...nord,

          javascript({ typescript: true }),

          tsFacet.of({env, path: "index.ts"}),

          tsSync(),

          autocompletion({
            override: [tsAutocomplete()],
          }),

          tsHover(),

          tsLinter(),

          EditorView.updateListener.of((update) => {
            if (update.docChanged) {
              console.log("update called")
              this.userCode = editor.state.doc.toString() || " "
            }
          }),

          //ViewPlugin.fromClass(class {
          //  update() {
          //    this.userCode = editor.state.doc.toString() || " "
          //    env.updateFile("index.ts", this.userCode)
          //  }
          //}),

          //linter(() => {
          //  const semantics = env.languageService.getSemanticDiagnostics("index.ts")
          //  const syntactics = env.languageService.getSyntacticDiagnostics("index.ts")
          //  const errors = semantics.concat(syntactics)

          //  return errors.map(({start, length, messageText}) => ({
          //    from: start,
          //    to: start + length,
          //    severity: "error",
          //    message: typeof messageText == "string" ? messageText : messageText.messageText,
          //  }))
          //}, {delay: 0}),

          //autocompletion({override: [(ctx) => {
          //  let completions = env.languageService.getCompletionsAtPosition("index.ts", ctx.pos, {})
          //  if (!completions) return {from: ctx.pos, options: []}

          //  const code = ctx.state.doc.toString()

          //  let word, from;
          //  for (let i = ctx.pos - 1; i >= 0; i--) {
          //    if ([' ', '.', '\n', ':', '{'].includes(code[i]) || i === 0) {
          //      from = i === 0 ? i : i + 1
          //      word = code.slice(from, ctx.pos).trim()
          //      break
          //    }
          //  }
          //  if (word) completions.entries = completions.entries.filter(({name}) => name.startsWith(word))

          //  return {
          //    from: ctx.pos,
          //    options: completions.entries.map(({name}) => ({
          //      label: name,
          //      apply: (view) => {
          //        view.dispatch({changes: {from, to: ctx.pos, insert: name}})
          //      },
          //    })),
          //  }
          //}]}),

          //hoverTooltip((view, pos, side) => {
          //  let {from, to, text} = view.state.doc.lineAt(pos)
          //  let start = pos, end = pos

          //  while (start > from && /\w/.test(text[start - from - 1])) start--;
          //  while (end < to && /\w/.test(text[end - from])) end++;
          //  if ((start == pos && side < 0) || (end == pos && side > 0)) return null;

          //  const program = env.languageService.getProgram()
          //  const checker = program.getTypeChecker()
          //  const file = program.getSourceFile("index.ts")

          //  let token
          //  if (pos >= parent.pos && pos < parent.end) {
          //    if (parent.kind >= ts.SyntaxKind.FirstToken && parent.kind <= ts.SyntaxKind.LastToken) {
          //      token = parent
          //    } else {
          //      outer: while (true) {
          //        for (const child of node.getChildren(file)) {
          //          if (child.end > pos && child.kind !== ts.SyntaxKind.JSDocComment) {
          //            if (isTokenKind(child.kind)) token = child
          //            // next token is nested in another node
          //            node = child
          //            continue outer
          //          }
          //        }
          //        break
          //      }
          //    }
          //  }

          //  const type = checker.getTypeAtLocation(token)

          //  console.log(checker.typeToString(type, undefined, ts.TypeFormatFlags.InTypeAlias))

          //  return {
          //    pos: start,
          //    end,
          //    above: true,

          //    create(_view) {
          //      let dom = document.createElement("div")
          //      const flags = ts.TypeFormatFlags.NoTruncation | ts.TypeFormatFlags.InTypeAlias
          //      const string = checker.typeToString(type, undefined, flags)
          //      dom.innerHTML = `<pre>${string}</pre>`
          //      return {dom}
          //    },
          //  }
          //}),
        ],
      })
      this.editor = editor
    })
  }
}

// // Colors from https://www.nordtheme.com/docs/colors-and-palettes
// // Polar Night
// const base00 = '#2e3440', // black
//   base01 = '#3b4252', // dark grey
//   base02 = '#434c5e',
//   base03 = '#4c566a' // grey
//
// // Snow Storm
// const base04 = '#d8dee9', // grey
//   base05 = '#e5e9f0', // off white
//   base06 = '#eceff4' // white
//
// // Frost
// const base07 = '#8fbcbb', // moss green
//   base08 = '#88c0d0', // ice blue
//   base09 = '#81a1c1', // water blue
//   base0A = '#5e81ac' // deep blue
//
// // Aurora
// const base0b = '#bf616a', // red
//   base0C = '#d08770', // orange
//   base0D = '#ebcb8b', // yellow
//   base0E = '#a3be8c', // green
//   base0F = '#b48ead' // purple
//
// const invalid = '#d30102',
//   darkBackground = '#252a33',
//   highlightBackground = base03,
//   background = base00,
//   tooltipBackground = base01,
//   selection = base03,
//   selectionMatch = '#4c566a80',
//   cursor = base04
//
// /// The editor theme styles for Nord.
// export const nordTheme = EditorView.theme(
//   {
//     '&': {
//       color: base04,
//       backgroundColor: background
//     },
//
//     '.cm-content': {
//       caretColor: cursor
//     },
//
//     '.cm-cursor, .cm-dropCursor': { borderLeftColor: cursor },
//     '&.cm-focused > .cm-scroller > .cm-selectionLayer .cm-selectionBackground, .cm-selectionBackground, .cm-content ::selection':
//       { backgroundColor: selection },
//
//     '.cm-panels': { backgroundColor: darkBackground, color: base03 },
//     '.cm-panels.cm-panels-top': { borderBottom: '2px solid black' },
//     '.cm-panels.cm-panels-bottom': { borderTop: '2px solid black' },
//
//     '.cm-searchMatch': {
//       backgroundColor: 'transparent',
//       outline: `1px solid ${base07}`
//     },
//     '.cm-searchMatch.cm-searchMatch-selected': {
//       backgroundColor: base04,
//       color: base00
//     },
//
//     '.cm-activeLine': { backgroundColor: selectionMatch },
//     '.cm-selectionMatch': {
//       backgroundColor: base05
//     },
//
//     '&.cm-focused .cm-matchingBracket, &.cm-focused .cm-nonmatchingBracket': {
//       outline: `1px solid ${base07}`
//     },
//
//     '&.cm-focused .cm-matchingBracket': {
//       backgroundColor: base06,
//       color: base02
//     },
//
//     '.cm-gutters': {
//       backgroundColor: base00,
//       color: base03,
//       border: 'none'
//     },
//
//     '.cm-activeLineGutter': {
//       backgroundColor: highlightBackground,
//       color: base04
//     },
//
//     '.cm-foldPlaceholder': {
//       backgroundColor: 'transparent',
//       border: 'none',
//       color: '#ddd'
//     },
//
//     '.cm-tooltip': {
//       border: 'none',
//       backgroundColor: tooltipBackground
//     },
//     '.cm-tooltip .cm-tooltip-arrow:before': {
//       borderTopColor: 'transparent',
//       borderBottomColor: 'transparent'
//     },
//     '.cm-tooltip .cm-tooltip-arrow:after': {
//       borderTopColor: tooltipBackground,
//       borderBottomColor: tooltipBackground
//     },
//     '.cm-tooltip-autocomplete': {
//       '& > ul > li[aria-selected]': {
//         backgroundColor: highlightBackground,
//         color: base03
//       }
//     }
//   },
//   { dark: true }
// )
//
// /// The highlighting style for code in the Nord theme.
// export const nordHighlightStyle = HighlightStyle.define([
//   { tag: t.keyword, color: base0A },
//   {
//     tag: [t.name, t.deleted, t.character, t.propertyName, t.macroName],
//     color: base08
//   },
//   { tag: [t.variableName], color: base07 },
//   { tag: [t.function(t.variableName)], color: base07 },
//   { tag: [t.labelName], color: base09 },
//   {
//     tag: [t.color, t.constant(t.name), t.standard(t.name)],
//     color: base0A
//   },
//   { tag: [t.definition(t.name), t.separator], color: base0E },
//   { tag: [t.brace], color: base07 },
//   {
//     tag: [t.annotation],
//     color: invalid
//   },
//   {
//     tag: [t.number, t.changed, t.annotation, t.modifier, t.self, t.namespace],
//     color: base0F
//   },
//   {
//     tag: [t.typeName, t.className],
//     color: base0D
//   },
//   {
//     tag: [t.operator, t.operatorKeyword],
//     color: base0E
//   },
//   {
//     tag: [t.tagName],
//     color: base0F
//   },
//   {
//     tag: [t.squareBracket],
//     color: base0b
//   },
//   {
//     tag: [t.angleBracket],
//     color: base0C
//   },
//   {
//     tag: [t.attributeName],
//     color: base0D
//   },
//   {
//     tag: [t.regexp],
//     color: base0A
//   },
//   {
//     tag: [t.quote],
//     color: base0F
//   },
//   { tag: [t.string], color: base0E },
//   {
//     tag: t.link,
//     color: base0E,
//     textDecoration: 'underline',
//     textUnderlinePosition: 'under'
//   },
//   {
//     tag: [t.url, t.escape, t.special(t.string)],
//     color: base07
//   },
//   { tag: [t.meta], color: base08 },
//   { tag: [t.monospace], color: base04, fontStyle: 'italic' },
//   { tag: [t.comment], color: base03, fontStyle: 'italic' },
//   { tag: t.strong, fontWeight: 'bold', color: base0A },
//   { tag: t.emphasis, fontStyle: 'italic', color: base0A },
//   { tag: t.strikethrough, textDecoration: 'line-through' },
//   { tag: t.heading, fontWeight: 'bold', color: base0A },
//   { tag: t.special(t.heading1), fontWeight: 'bold', color: base0A },
//   { tag: t.heading1, fontWeight: 'bold', color: base0A },
//   {
//     tag: [t.heading2, t.heading3, t.heading4],
//     fontWeight: 'bold',
//     color: base0A
//   },
//   {
//     tag: [t.heading5, t.heading6],
//     color: base0A
//   },
//   { tag: [t.atom, t.bool, t.special(t.variableName)], color: base0C },
//   {
//     tag: [t.processingInstruction, t.inserted],
//     color: base07
//   },
//   {
//     tag: [t.contentSeparator],
//     color: base0D
//   },
//   { tag: t.invalid, color: base02, borderBottom: `1px dotted ${invalid}` }
// ])
//
// /// Extension to enable the Nord theme (both the editor theme and
// /// the highlight style).
// const nord = [nordTheme, syntaxHighlighting(nordHighlightStyle)]
