import { Controller } from "@hotwired/stimulus"
import { useTransition } from "stimulus-use"

export default class Dropdown extends Controller {
  static targets = ["menu"]

  connect() {
    useTransition(this, {element: this.menuTarget})
  }

  toggle() {
    this.toggleTransition()
  }

  hide() {
    this.leave()
  }
}
