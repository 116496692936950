import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "select", "template"]

  connect() {
    super.connect()

    this.containerTarget = this.element
  }

  disconnect() {
  }

  addTag(event) {
    const id = event.target.value
    const name = event.target.options[event.target.selectedIndex].text

    // Disable fake (visible) option
    this.selectTarget.querySelectorAll(`option[value='${id}']`)[0].setAttribute("disabled", true)

    // Add fake (visible) tag
    const tag = this.templateTarget.content.cloneNode(true)
    tag.querySelectorAll("div")[0].setAttribute("data-tag-id", id)
    tag.querySelectorAll("button")[0].setAttribute("data-manytag-id-param", id)
    tag.querySelectorAll("span")[0].innerText = name
    this.containerTarget.appendChild(tag)

    // Select action (hidden) option
    this.inputTarget.querySelectorAll(`option[value='${id}']`)[0].setAttribute("selected", true)

    // Keep dropdown open
    this.selectTarget.click()
  }

  removeTag(event) {
    console.log('hurr')
    const id = event.params.id

    // Deselect actual (hidden) option
    this.inputTarget.querySelectorAll(`option[value='${id}']`)[0].removeAttribute("selected")
    // Remove fake (visible) tag
    this.containerTarget.querySelectorAll(`div[data-tag-id='${id}']`)[0].remove()
    // Enable fake (visible) option
    this.selectTarget.querySelectorAll(`option[value='${id}']`)[0].removeAttribute("disabled")
  }
}
