import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {cols: Number}

  onKeyDown(event) {
    if (event.ttskip) return

    const [, row, col] = event.target.id.split("-").map((x) => parseInt(x))
    const lastRow = parseInt(this.element.lastElementChild.getAttribute("data-table-row"))

    const key = event.key
    const enter = key == "Enter"
    const tab = key == "Tab"
    const shift = event.shiftKey
    const ctrl = event.ctrlKey

    const first = 0
    const last = this.colsValue

    const top  = (key == "Home" && !shift) || (ctrl && key == "ArrowUp")
    const bot  = (key == "Home" && !shift) || (ctrl && key == "ArrowDown")
    const home = (key == "Home" &&  shift) || (ctrl && key == "ArrowLeft")
    const end  = (key == "Home" &&  shift) || (ctrl && key == "ArrowRight")

    const up    = !top  && (key == "ArrowUp"    || (enter &&  shift))
    const down  = !bot  && (key == "ArrowDown"  || (enter && !shift))
    const left  = !home && (key == "ArrowLeft"  || (tab   &&  shift))
    const right = !end  && (key == "ArrowRight" || (tab   && !shift))

    console.log(`table#onKeyDown`, {ctrl, key, top, bot, home, end, up, down, left, right})

    if (top)  return this.focus(event, 1,         col)
    if (bot)  return this.focus(event, lastRow, col)
    if (home) return this.focus(event, row,       first)
    if (end)  return this.focus(event, row,       last)

    if (up)   return this.focus(event, row - 1, col)
    if (down) return this.focus(event, row + 1, col)

    if (left && col <= first) return this.focus(event, row, last)
    if (left)                 return this.focus(event, row, col - 1)
    if (right && col >= last) return this.focus(event, row, first)
    if (right)                return this.focus(event, row, col + 1)
  }

  focus(event, row, col) {
    console.log(`table#focus`, {row, col})

    const el = document.getElementById(`cell-${row}-${col}`)

    event.stopPropagation()
    event.preventDefault()

    el.focus()
    if (el.select) el.select()
  }
}
