// Configure your import map in config/importmap.rb. Read more: https://github.com/rails/importmap-rails
import "@hotwired/turbo-rails"
import "./controllers"

console.log('refresh document')
//document.addEventListener('turbo:visit', (e) => console.log(`visit ${e.detail.action}`))
//document.addEventListener('turbo:morph', () => console.log('morph render'))
//document.addEventListener('turbo:frame-render', () => console.log('frame render'))
//document.addEventListener('turbo:before-stream-render', (e) => console.log(`stream render (${e.target.getAttribute('action')} event)`, e.target))

import * as ActionCable from '@rails/actioncable'
ActionCable.logger.enabled = true
